<template>
    <div>
        <header-portrate />
    <div style="margin-bottom:70px !important;">
        <bread-crumbs :items="items" />
        <tabs-comp :tabs="tabs" />

        <v-row class="mainrow m-2" :style="`direction:`+lang.dir+`;margin-top:20px !important;`">
            <v-col
            cols="12"
            md="8"
            sm="12">
            <b-input-group class="mt-3" >
                <b-form-input v-model="account" autocomplete="off" list="classes-list" @change="getaccountname(item.classid + ' - ' + item.namear)"></b-form-input>
                <datalist id="classes-list">
                    <option v-for="(item,index) in classes" :key="index">{{item.classid}} - {{ item.namear }}</option>
                </datalist>
                <v-select
                    
                    item-text="text"
                    item-value="value"
                    style="width:0 !important;height:10px;display:none"
                    v-model="account"
                    :items="accounts" 
                    :label="lang.accounts"
                    
                    @change="getaccountname()"
                    ></v-select>
                <b-form-input placeholder="Start Date" style="border:1px solid #000" type="date" v-model="sdate"></b-form-input>
                <b-form-input placeholder="End Date" style="border:1px solid #000" type="date" v-model="edate"></b-form-input>
                <b-input-group-append style="border:1px solid #000">
                    <b-button variant="light" style="background: gold !important;font-size:14px;color:#000;font-size:14px;padding:8px 22px;border-radius:5px" @click="getReport()"><i class="fas fa-search" style="color:000;font-weight:bold;"></i></b-button>
                </b-input-group-append>
            </b-input-group>
            </v-col>
            <v-col cols="12" md="4" sm="12" class="text-right">
                <b-button variant="light" style="width:145px;margin-right:9px" class="mytab mytab1 mt-3" @click="exportE('e')">{{lang.export_excel}}</b-button>
                <b-button variant="light" style="width:145px;margin-right:3px;background:red;color:#FFF;display:none" class="mytab mt-3" @click="exportE('p')">PDF تصدير</b-button>
            </v-col>
        
            <v-col cols="12" md="12" sm="12" class="mt-1">
                <v-simple-table>
                    <thead>
                        <tr>
                            <th colspan="6" class="text-center">{{lang.account_statement}} - {{account_name}}</th>
                        </tr>
                        <tr>
                            <th class="text-center">{{lang.date}}</th>
                            <th class="text-center">{{lang.jvnom}}</th>
                            <th class="text-center">{{lang.description}}</th>
                            <th class="text-center">{{lang.depit}}</th>
                            <th class="text-center">{{lang.credit}}</th>
                            <th class="text-center">{{lang.balance}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,index) in accountrows" :key="index">
                            <td class="text-center" style="direction:ltr">{{item.date}}</td>
                            <td class="text-center" style="direction:ltr">{{item.jvnom}}</td>
                            <td class="text-center" style="direction:ltr">{{item.notes}}</td>
                            <td class="text-center" style="direction:ltr">{{item.depit}}</td>
                            <td class="text-center" style="direction:ltr">{{item.credit}}</td>
                            <td class="text-center" style="direction:ltr">{{item.balance}}</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <th colspan="3" class="backBlack">{{ lang.totals }}</th>
                            <th class="backGreen">{{ allTotals.debit }}</th>
                            <th class="backRed">{{ allTotals.credit }}</th>
                            <th class="backEmpty"></th>
                        </tr>
                        <tr>
                            <th colspan="6"> {{lang.balance}} : {{allTotals.balance}} </th>
                        </tr>
                    </tfoot>
                </v-simple-table>
            </v-col>
        </v-row>
    </div>
    <Footer />
    </div>
</template>

<script>
import breadCrumbs from '@/components/breadCrumbs.vue';
import TabsComp from '@/components/tabsComp.vue';
import axios from 'axios'
import HeaderPortrate from '@/components/Header-Portrate.vue';
import Footer from '@/components/Footer.vue';
export default {
  components: { breadCrumbs ,TabsComp, HeaderPortrate, Footer},
    data()
     {
        return {
            sdate: new Date().toISOString().substring(0, 10),
            edate: new Date().toISOString().substring(0, 10),
            accountrows: [],
            accounts: [],
            account: '',
            account_name: '',
            classes: [],
        }
    },
    computed: {
        allTotals: function(){
            let t = {
                debit: 0,
                credit: 0,
                balance: 0,

            }
            for(let i=0;i<this.accountrows.length;i++){
                t.debit = parseFloat(t.debit) + parseFloat(this.accountrows[i].depit)
                t.credit = parseFloat(t.credit) + parseFloat(this.accountrows[i].credit)
            }
            t.balance = parseFloat(t.debit) - parseFloat(t.credit)
            return t;
        },
        btotals: function(){
            let t = 0;
            for(let i=0;i<this.accountrows.length;i++){
                t = parseFloat(t) + parseFloat(this.accountrows[i].balance)
            }
            t = this.$RoundNum(t);
            if(t < 0){
                return "("+parseFloat(t) * parseFloat(-1)+")";
            }else{
                return t;
            }
        },
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        items: function(){
            return {
                    text: this.lang.account_statement,
                    disabled: true,
                    to: '/reports/statment-report',
                }
        },
        tabs: function() {
            const menu = this.$store.getters['state'].menu;
            const license = this.$store.getters['state'].licenseType.activemenu;
            const alicense = this.$store.getters['state'].licenseType
            let currentMenu = menu[license];
            if(!license){
              return false;
            }
            let cMenu = {main: {},sub:[]};
            Object.values(currentMenu).forEach(value =>{
            const cM = localStorage.getItem('currentMenu');
            if(value.main.index == cM){
                cMenu = value.sub;
            }else if(cM == null && value.main.index == 1){
                cMenu = value.sub;
            }
            });
        
            let t = []
            Object.values(cMenu).forEach(value =>{
            
                if(this.$route.path == value.href){
                    value.class = "mytab";
                }else{
                    value.class = "mytab4";
                }
                if(this.lang.langname == 'ar'){
                    value.name = value.arname
                }else{
                    value.name = value.enname
                }
                for(let i =0; i< value.groupid.length;i++){
                    if(value.groupid[i] == this.$store.getters['state'].group_id){
                    if(alicense[value.perms[0]] || value.perms[0] == 'any')
                        t.push(value)
                    }
                }
            
            })
        
            return t;
        },
        headers: function(){
            return '';
        }
    },
    created() {
        this.getclasses();
    },
    methods: {
        getclasses() {
            const post = new FormData();
            post.append("type","getPostedCOA")
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("auth",this.$cookies.get(this.$COOKIEPhase))
            post.append("data[lang]",this.lang.langname);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {  
                const res = response.data;    
                this.classes = res.results.data;
            });
        },
        getaccountname(vars){
            this.account_name = vars;
            // this.accountrows = [];
            // if(this.accoutn == 0){
            //     return false;
            // }
            // for(let i =0 ; i<this.accounts.length;i++){
            //     if(this.accounts[i].value == this.account){
            //         this.account_name = this.accounts[i].text
            //     }
            // }
        },
        getAccounts(){
            const post = new FormData();
            post.append('type',"getAccoutns");
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[lang]',this.$cookies.get(this.$store.state.LangCooki));
            post.append('data[sdate]',this.sdate);
            post.append('data[edate]',this.edate);
            axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            ).then((response) => {
                const res = response.data;
                // console.log("this is accounts",res);
                this.accounts = res.results.data;
            })
        },  
        getReport(){
            const post = new FormData();
            post.append('type',"getStatReport_old");
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[lang]',this.$cookies.get(this.$store.state.LangCooki));
            post.append('data[sdate]',this.sdate);
            post.append('data[edate]',this.edate);
            post.append('data[account]',this.account);
            axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            ).then((response) => {
                const res = response.data;
                // // console.log(res);
                if(typeof res.results.data !== 'undefined')
                {
                    this.accountrows = res.results.data;
                }
            })
        }
    },
}
</script>